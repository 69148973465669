
	import { getPayManageList, getShouldPaySummary, postDeductRemainingFee } from '@/api/pay'
	import { defineComponent, onMounted, reactive, ref, toRef} from 'vue'
  import { Buttons, Inputs, TableColumns } from '@/types/BasicComponent'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import BasicTablePage from '@/components/BasicPageLayout/BasicTablePage.vue'
	import usePagination from '@/utils/composables/usePagination'
  import BasicFormDialog from '@/components/BasicPageLayout/BasicFormDialog.vue'
  
	export default defineComponent({
		components: { BasicTablePage, BasicFormDialog },
		setup(){
			const tablePagination = usePagination()
			
      const searchData = reactive<AnyObject>({})
      
      const tableData = reactive<AnyArray>([])
			
      const searchInputs = reactive<Inputs>([
        {
          label: '胶合板厂',
          type: 'input',
          placeholder: '胶合板厂名称',
          model: toRef(searchData, 'jhbcName'),
          width: '200px',
          clearable: true
        }, {
          label: '旋切机主',
          type: 'input',
          placeholder: '旋切机主名称',
          model: toRef(searchData, 'xqjzName'),
          width: '200px',
          clearable: true
        }, {
          label: '订单号',
          type: 'input',
          placeholder: '订单号',
          model: toRef(searchData, 'orderCode'),
          width: '200px',
          clearable: true
        }
      ])
      
      const searchButtons = reactive<Buttons>([
      	{
      	  label: '查询',
      	  icon: 'el-icon-search',
      	  onClick: () => {
            getTableData()
          }
      	}
      ])
      
      const payItemInfos = reactive<AnyObject>({ isShowDialog: false })
      
			const tableColumns = reactive<TableColumns>([
				{
					type: 'index',
					label: '序号',
					width: '80px',
					align: 'center'
				}, {
					prop: 'orderCode',
					label: '订单号',
					align: 'center'
				}, {
					prop: 'jhbName',
					label: '胶合板厂',
					align: 'center'
				}, {
					prop: 'xqjName',
					label: '旋切机主',
          align: 'center'
				}, {
					prop: 'nowPlatformName',
					label: '当前平台',
					align: 'center'
				}, {
					prop: 'nowPlatformNumber',
					label: '当前平台电话',
					align: 'center'
				}, {
					prop: 'payPlatformName',
					label: '支付平台',
					align: 'center'
				}, {
					prop: 'payPlatformNumber',
					label: '支付平台电话',
					align: 'center'
				}, {
					prop: 'orderPayStatus',
					label: '支付状态',
					align: 'center',
          type: 'status',
          statusType: [
            { value: 1, label: '未支付', type: 'info' },
            { value: 2, label: '已支付', type: 'success' },
            { value: 3, label: '支付失败', type: 'danger' }
          ]
				}, {
					type: 'button',
					label: '操作',
          align: 'center',
					buttons: [{
            label: '申请支付',
            onClick: async scope => {
              const infos = scope.row
              if(infos.orderPayStatus == 2){
                return !ElMessage.success('订单已支付')
              }
              const respone = await getShouldPaySummary(+infos.orderId, +infos.jhbId)
              const datas = (respone.data ? respone.data.data : null)
              if(datas){
                payItemInfos.goodsFeeSum = datas.goodsFeeSum
                payItemInfos.serviceFeeSum = datas.serviceFeeSum
                payItemInfos.shouldPaySum = datas.shouldPaySum
                payItemInfos.isNeedServiceFeeCharge = (datas.serviceFeeChargeType == 1)
                payItemInfos.itemList = (datas.details || []).map((item: AnyObject) => {
                  return {
                    orderId:    infos.orderId,
                    orderCode:  infos.orderCode,
                    xqjzName:   infos.xqjName,
                    goodsFee:   item.goodsFee,
                    serviceFee: item.serviceFee,
                    shouldPay:  item.shouldPay
                  }
                })
                payItemInfos.isShowDialog = true
                payItemInfos.payPassword = ""
                payItemInfos.jhbcID = infos.jhbId
              }
              return true
            }//onclick
          }]
        }
			])
			
      const getTableData = async () => {
      	const respone = await getPayManageList(Object.assign({ pageNo: tablePagination.currentPage, pageSize: tablePagination.pageSize }, searchData))
        tableData.splice(0, tableData.length)
      	if(respone.data && respone.data.data){
          (respone.data.data.records || []).forEach((item: AnyObject) => {
            tableData.push(item)
          })
      	}
      }
      
      const closeDialog = () => {
        payItemInfos.isShowDialog = false
        payItemInfos.itemList = null
      }
      
      const submitPayApply = async () => { //提交支付申请
        if(!payItemInfos.payPassword){
          return !ElMessage.error('请输入支付密码')
        }
        
        const oids = (payItemInfos.itemList || []).map((item: AnyObject) => item.orderId)
        
        if(oids.length){
          await postDeductRemainingFee({
            orderIds: oids, 
            payPassword: payItemInfos.payPassword, 
            jhbId: payItemInfos.jhbcID,
          })
          ElMessage.success('已提交申请付款')
        }
        
        return true
      }
      
			onMounted(() => {
				getTableData()
			})
			
			return {
        getTableData,
				tableColumns,
				tableData,
				tablePagination,
        searchInputs,
        searchButtons,
        payItemInfos,
        closeDialog,
        submitPayApply
			}
		}
	})
