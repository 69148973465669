import { BaseResponse, C6BaseResponse, C6PageRequest, C6PageResponse, PageRequest } from '@/types/API'
import { FileType, SourceType } from '@/types/Common'
import request, { c6Request } from '@/utils/request'

/** 获取平台账户信息列表 */
export function getPlatformBankAccountList(params: C6PageRequest<PlatformBankAccountListQueryParams>) {
  return c6Request.get<C6BaseResponse<C6PageResponse>>(`backstage/payprocess/getBankPlatformAccountPage`, { params })
}

/** 获取平台账户详情流水 */
export function getBankTraderFlowList(params: PageRequest<PlatformBankAccountListQueryParams>) {
  return c6Request.get<C6BaseResponse<C6PageResponse>>(`backstage/payprocess/getBankTraderFlowPage`, { params })
}

/** 添加或修改平台账户信息 */
export function addOrUpdateBankPlatformAccount(data: PlatformBankAccountAddOrUpdateData) {
  return c6Request.post<C6BaseResponse>(`backstage/payprocess/addOrUpdateBankPlatformAccount`, data)
}

/** 获取支付流程分页 */
export function getAccountProcessList(params: C6PageRequest<PlatformPayProcessQueryParams>) {
  return c6Request.get<C6BaseResponse<C6PageResponse>>(`backstage/payprocess/getPayProcessPage`, { params })
}

/** 保存支付流程 */
export function savePayProcess(data: SavePayProcessData) {
  return c6Request.post<C6BaseResponse>(`backstage/payprocess/updateUserPayProcess`, data)
}

/** 获取平台账户流水 */
export function getPlatformAccountTraderFlow(params: C6PageRequest<PlatformAccountTraderFlowQueryParams>) {
  return c6Request.get<C6BaseResponse<C6PageResponse>>(`backstage/payprocess/getBankTraderFlowPage`, { params })
}

/** 后台支付管理列表 */
export function getPayManageList(params: AnyObject){
  return c6Request.get<C6BaseResponse<C6PageResponse>>(`/backstage/orderpayment/payPage`, { params })
}

/* 获取订单应付金额汇总 */
export function getShouldPaySummary(oid: number, jid: number){
  return c6Request.get<C6BaseResponse>(`/order/orderpayment/getShouldPaySummaryByJhb`, { params: { orderIds: oid, jhbId: jid } })
}

/* 获取订单应付金额汇总 */
export function postDeductRemainingFee(data: AnyObject){
  return c6Request.post<C6BaseResponse>(`backstage/orderpayment/deductRemainingFee`, data)
}

/** 根据平台账户id获取盖章图片 */
export function getPlatformSealImg(platformAccountId: number) {
  return c6Request.get<C6BaseResponse>(`backstage/file/getFileByType`, { params: { sourceTypeId: SourceType.BANK_PLATFORM_ACCOUNT, fileTypeId: FileType.SEAL_IMG, sourceId: platformAccountId } })
}

export interface PlatformBankAccountListQueryParams {
  bankAccountName?: string,
  bankAccountNumber?: string
}

export interface BankTraderFlowListParams {
  
}

export interface PlatformBankAccountAddOrUpdateData {
  bankCode?: string,
  bankName?: string,
  bankAccountNumber?: string,
  bankAccountName?: string,
  bankDescription?: string,
  id?: number,
  bankIsValid?: number
}

// export interface PlatformBankAccountUpdateData extends PlatformBankAccountAddData {
//   id?: number,
//   bank_is_valid?: number
// }

export interface PlatformPayProcessQueryParams {
  xqjzName?: string,
  jhbcName?: string,
  accountType: number,
  areaCode?: string,
  platformAccountName?: string
}

export interface SavePayProcessData {
  accountType: number,
  accountId: number,
  processList: {
    bpaId: number,
    ratio: number,
    priority: number
  }[]
}

export interface PlatformAccountTraderFlowQueryParams {
  platformBankAccountNumber: string,
  traderStartTime?: Date,
  traderEndTime?: Date,
  traderType?: 1 | 2
}